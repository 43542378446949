.landingPageContainer {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 126px;
  background-color: #f5f6f8;
  padding-left: 8%;
  padding-right: 8%;
}

.landingPageContainer .left-section {
  flex: 1 1 30%;
}

.landingPageContainer .right-section {
  padding-left: 20px;
  flex: 1 1 70%;
}

.section-title {
  font-family: "Lato-Bold";
  font-style: normal;
  font-size: 28px;
  line-height: 34px;
  color: #060f21;
  padding-bottom: 25px;
}

@media only screen and (max-width: 650px) {
  .section-title {
    font-family: Lato;
    font-family: "Lato-Bold";
    font-style: normal;
    font-size: 22px;
    line-height: 26px;
    color: #060f21;
    margin-bottom: 0;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .landingPageContainer {
    padding-top: 91px;
    display: flex;
    flex-direction: column;
    padding-left: 2em;
    padding-right: 2em;
  }

  .landingPageContainer .left-section {
    flex: 1 1 100%;
  }

  .landingPageContainer .right-section {
    padding-left: 0;
    flex: 1 1 100%;
  }
}
