button:focus {
  outline: none;
}
.dropdown-menu {
  visibility: visible;
  display: block;
  padding-top: 20px;
  padding-bottom: 15px;
  /* left: -50%; */
  position: absolute;
  opacity: 1;
  top: 65px;
  transition: all 0.3s ease-in-out 0.5s, visibility 0.5s linear 0.3s, z-index 0s linear 10ms, opacity 0.5s ease-in-out;
  transition-delay: 0s, 0s, 0.3s;
  transform-origin: top center;
  box-shadow: -1px -1px 4px -1px rgba(0, 0, 0, 0.2), 2px 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 10px 20px;
  /* width: 235px; */
  /* padding-bottom: 10px; */
  left: 50%;
  transform: translateX(-48%);
}
ul.dropdown-menu:before {
  position: absolute;
  top: -18px;
  right: 0%;
  opacity: 0;
  background-color: purple;
  width: inherit;
  height: 18px;
  content: "";
  cursor: default;
}
ul.dropdown-menu:after {
  position: absolute;
  top: -7px;
  left: 45%;
  background: #fff;
  height: 14px;
  box-shadow: -1px -1px 0px -1px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1);
  width: 14px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
}
ul.dropdown-menu li {
  padding-top: 10px;
    padding-bottom: 0px;
    width: 100%;
    display: flex;
    clear: both;
    font-size: 16px;
    color: rgb(33, 37, 41);
    text-align: center;
    white-space: nowrap;
    border: 0px;
    padding-bottom: 15px;
    /* width: 70%; */
}
.dropdown-menu a {
  color: rgb(33, 37, 41);
}
