
 @media (min-width: 300px) {
  .buttonsContainer {
    margin-top:50px;
  }

}

@media (min-width: 500px) {
  .buttonsContainer {
    margin-top:0px;
    grid-template-columns:repeat(auto-fill, minmax(173px, 1fr) )
  }
} 