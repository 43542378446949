.programApprovalContainer {
  display: flex;
  width: 100%;
  min-height: 290px;
  margin-bottom: 24px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: center;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 12px 19px 35px -8px rgb(0 0 0 / 5%);
  border: solid 1px rgba(159, 169, 186, 0.2);
}

.programApprovalTextContainer {
  margin: 64px 68px 25px 0px;
  height: fit-content;
  flex: auto;
  width: 100%;
  width: calc(100% - 552px);
}

.programApprovalHeader {
  /* width: 250px; */
  width: fit-content;
  min-height: 28px;
  height: fit-content;
  font-family: Lato-SemiBold;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.91;
  letter-spacing: 0.76px;
  color: #030d26;
  margin-bottom: 5px;
}

.programApprovalTopContent {
  height: fit-content;
  min-height: 121px;
  margin-bottom: 10px;
}

.programApprovalImageContainer {
  /* margin-top: 43px;
  margin-left: 49px;
  margin-bottom: 45px; */
  margin: auto 73px auto 49px;
  float: left;
  width: 360px;
  height: 202px;
  flex: initial;
  overflow: hidden;
  display: block;
}

.programApprovalImage {
  width: 100%;
}

.programApprovalMessage {
  width: 100%;
  min-width: 250px;
  min-height: 60px;
  height: fit-content;
  font-family: Lato-Regular;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.3px;
  color: #292e4c;
  /* text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3; */
}

.programApprovalButton {
  margin-bottom: 29px;
  width: 147px;
  height: fit-content;
  /* border-radius: 2px;
  background-color: #e1373a; */
  justify-content: center;
  align-items: center;
  /* position: absolute; */
}

/* .programApprovalButton p {
  width: 102.1px;
  height: 20px;
  margin: auto;
  font-family: Lato-SemiBold;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
} */

@media only screen and (max-width: 1101px) {
  /* .programApprovalImage {
    margin: 43px 73px 76px 49px;
    width: 252px;
    height: 141px;
  } */
  .programApprovalTextContainer {
    /*TODO: FIND SCALE POINTS*/
    /* margin-top: 65px;
    margin-right: 68px; */
  }
}

@media only screen and (max-width: 981px) {
  /* .programApprovalImage {
    width: 180px;
    height: 101px;
    width: 252px;
    height: 141px;
    margin-top: 70px;
    margin: 43px 73px 76px 49px;
  } */
}

@media only screen and (max-width: 881px) {
  .programApprovalImageContainer {
    width: 250px;
    height: auto;
    margin: auto 25px auto 49px;
  }
}

@media only screen and (max-width: 685px) {
  .programApprovalHeader {
    width: 100%;
  }

  .programApprovalContainer {
    flex-direction: column;
    height: fit-content;
  }

  .programApprovalImageContainer {
    width: calc(90% - 30px);
    height: auto;
    margin: 25px auto 15px auto;
  }

  .programApprovalTopContent {
    min-height: fit-content;
  }

  .programApprovalTextContainer {
    margin: 15px auto 25px auto;
    width: calc(100% - 70px);
  }

  .programApprovalButton {
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 550px) {
  .programApprovalHeader {
    /* height: fit-content;
    width: 70vw;
    margin-bottom: 15px;
    line-height: 1.33; */
    inline-size: max-content;
  }
}
