.personalInfoInputsThree {
    display: grid;
    grid-column-gap:20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "city1 state1 country1"
      "city2 state2 country2"
      "city3 state3 country3"
  }
  
  .personalInfoInputsThree .select-input {
    margin-top: 5px;
  }
  