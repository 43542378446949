.firstName {
  grid-area: firstName;
}
.lastName {
  grid-area: lastName;
}
.email {
  grid-area: email;
}
.password {
  grid-area: password;
}
.confirmPassword {
  grid-area: confirmPassword;
}

 @media (min-width: 425px) {
  .createAccountContainer {
    grid-template-columns: 1fr;
    grid-template-areas: 
      "firstName"
      "lastName"
      "email"
      "password"
      "confirmPassword"
  }
}

@media (min-width: 768px) {
  .createAccountContainer {
    display: grid;
    grid-column-gap:20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
      "firstName lastName"
      "email email"
      "password password"
      "confirmPassword confirmPassword"
  }
}

@media (min-width: 992px) {
  .createAccountContainer {
    display: grid;
    grid-column-gap:20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
      "firstName lastName"
      "email email"
      "password password"
      "confirmPassword confirmPassword"
  }
} 

.isSpouse {
  margin-left: 12px;
}


.btn-red {
  background-color: rgb(225, 55, 58);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(225, 55, 58);
}

.btn-red:hover {
  color: rgb(225, 55, 58);
  background-color: rgb(255, 255, 255);
}
.btn-red svg {
  display: none;
}
.btn-red.active svg {
  display: inline-block;
}

.text-muted-label {
  font-size: 1rem;
  max-width: 500px;
}