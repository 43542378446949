@media (max-width: 425px) {
  .formFooterContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0;
  }
}

@media (min-width: 425px) {
  .formFooterContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0;
  }
}

@media (min-width: 768px) {
   .formFooterContainer{
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .formFooterContainer{
    display: flex;
    flex-direction: row;
    margin: 0;
  }
} 