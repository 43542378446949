.event-container {
	background: #FFFFFF;
	box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	display: flex;
	flex-flow: column;
	padding: 24px;
  margin-right: 32px;
}

.event-details {
	display: flex;
	flex-flow: column;
	padding-bottom: 20px;
	border-bottom: 1px solid #C4C4C4;
	margin-bottom: 0;
}

.event-name {
	font-family: Lato-bold;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: #060F21;
	margin-bottom: 4px;
}

.event-date {
	font-family: Lato-Regular;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #2F539D;
}

.event-info {
	font-family: Lato-Regular;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	margin-top: 4px;
	color: #545158;
}

.event-remainder {
	font-family: Lato-Regular;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	margin-top: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #C4C4C4;
}

.event-remainder span {
	color: #E1373A;
}

.event-link {
	border-radius: 5px;
	border: 1px solid #E1373A;
	margin-top: 20px;
	display: flex;
	justify-content: center;
	padding: 10px;
}

.event-link a {
	font-family: Lato-Regular;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */

	text-align: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;

	/* Commit/Red */

	color: #E1373A;
}

.event-link-button {
	border: solid 1px;
  border-radius: 3px;
	border-color: #e1373a;
	transition: all 0.3s ease-in-out;
	background-color: #e1373a;
	color: #FFFFFF;
	font-family: Lato-Regular;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	width: 100%;
	margin-top: 20px;
	min-height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.event-link-button:hover {
	background-color: #FFFFFF;
	color: #E1373A;
	border-radius: 3px;
	border: solid 1px;
	border-color: #E1373A;
	transition: all 0.3s ease-in-out;
	text-decoration: none;
}