@media (max-width: 600px) {
  .pypModuleTitle {
    margin-left:10px
  }
  .pypModuleDescription{
    margin-bottom: 8.5vh;
    margin-left:10px;
    width: 100%;
  }
}

@media (min-width: 600px) {
  .pypModuleTitle {
    margin-left:30px
  }
  .pypModuleDescription{
    margin-left:30px;
    width: 40%;
  }
}