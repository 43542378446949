.cropperButtonContainer {
  display: flex;
}

.cropperButtonContainer > button {
  margin: 5px;
}

.cropperContainer {
  width: 500px;
  height: 400px;
  padding-bottom: 45px;
  padding-top: 10px;
}

.cropperImageContainer {
  width: 300px;
  height: 300px;
  margin: auto;
  margin-bottom: 40px;
}

@media only screen and (max-width: 800px) {
  .cropperContainer {
    width: 100%;
    height: 400px;
    padding-bottom: 40px;
  }

  .cropperImageContainer{
    width: 100%;
  }
}
