.arrow-left {
  width: 0; 
  height: 45px; 
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; 
  border-right:20px solid #fff; 
}

.arrow-right{
  width: 0; 
  height: 45px; 
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; 
  border-left:20px solid #fff; 
}

.arrow-down {
  width: 45px; 
  height: 0px; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fff;
}

.arrow-up {
  width: 45px; 
  height: 0px; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #fff;
}

