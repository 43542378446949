.draggableRoadmapperElement {
  display: flex;
  min-height: 44px;
  width: 100%;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin: 5px 0px;
}

.roadmapperEventEditIcon:hover {
  color: rgba(0, 0, 0, 0.55);
}

.roadmapperDisplayedTime {
  /* min-width: 105px; */
}

.roadmapperEventTitle {
  min-width: 350px;
  max-width: 379px;
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 116%;
  text-align: left;
  color: #030d26;
  margin-left: 28px;
  height: 21px;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.roadmapperEventConstraintTitle {
  max-width: 350px;
  min-width: 75px;
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 116%;
  display: flex;
  text-align: left;
  align-items: center;
  color: #030d26;
  margin-left: 51px;
  height: fit-content;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 700px) {
  .roadmapperEventTitle {
    min-width: 200px;
  }
  .draggableRoadmapperElement {
    /* min-height: 80px; */
  }
  .roadmapperDisplayedTime {
    min-width: 0px;
  }
}

@media only screen and (max-width: 500px) {
  .roadmapperEventTitle {
    margin-left: 15px;
    min-width: 75px;
  }
  .roadmapperDisplayedTime {
    min-width: 0px;
  }
  .roadmapperEventConstraintTitle {
    margin-left: 38px;
  }
}
