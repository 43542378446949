.webinar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  background: #ffffff;
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 24px;
}

.webinar-video-container {
  width: 100%;
  color: #030e25;
}

.webinar-video-container .video-react .video-react-video,
.webinar-video-container .video-react.video-react-fluid {
  border-radius: 5px;
}

.webinar-video-container .video-react button.video-react-big-play-button {
  background-color: transparent !important;
  border: none !important;
  color: #d8f0f8 !important;
  font-size: 6em;
}

.webinar-video-container .video-react .video-react-big-play-button:focus {
  background-color: transparent;
}

.webinar-video-container .subject-info-container {
  display: flex;
  margin-top: 24px;
}

.webinar-video-container .user-profile-image-container {
  margin-right: 12px;
}

.webinar-video-container .user-profile-image {
  border-radius: 100px;
  height: 50px;
  width: 50px;
}

.webinar-video-container .subject-name {
  font-family: Lato-Bold;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0;
}

.webinar-video-container .subject-job {
  font-family: Lato-Regular;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* Commit / Dark-Gray */

  color: #545158;
}

.webinar-video-container .video-description {
  font-family: Lato-Regular;
  font-size: 16px;
  line-height: 19px;
}

.session-description {
  color: #030e25;
  font-family: Lato-Regular;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 24px;
}

.no-webinar-message {
  font-family: Lato-Regular;
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 22px;
  color: #030e25;
  text-align: center;
}
