.userProfileThumbnailContainer {
  height: 100%;
  padding-top: 34px;
  padding-bottom: 19px;
}

.userProfileImageCircle {
  width: 196px;
  height: 197px;
  border: 3.14px solid red;
  border-radius: 96px;
  margin: auto;
  position: relative;
}

.profileImageThumbnail {
  border-radius: 133.2px;
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 2;
}

.profileImageThumbnail:hover {
  z-index: 0;
}

.hiddenUserUploadField {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.hiddenUserUploadField:focus {
  outline: none;
}

.userProfileImageLabelField {
  object-fit: contain;
  position: absolute;
  top: 3px;
  left: 2px;
  height: 98%;
  width: 98%;
  z-index: 1;
  cursor: pointer;
}

.userProfileImageDefaultLabelField {
  object-fit: contain;
  cursor: pointer;
}

.userProfileImageLabelField:hover {
  z-index: 3;
  visibility: visible;
}

@media only screen and (max-width: 650px) {
  .userProfileThumbnailContainer {
    height: 100%;
    padding: 0;
  }

  .userProfileImageCircle {
    width: 70px;
    height: 70px;
    border: 3.14px solid red;
    border-radius: 96px;
    margin: auto;
    position: relative;
  }

  .profileImageThumbnail {
    border-radius: 133.2px;
    position: absolute;
    z-index: 2;
  }

  .profileImageThumbnail:hover {
    z-index: 0;
  }

  .hiddenUserUploadField {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
  }

  .hiddenUserUploadField:focus {
    outline: none;
  }

  .userProfileImageLabelField {
    object-fit: contain;
    position: absolute;
    top: -3px;
    left: -3px;
    height: 98%;
    width: 98%;
    z-index: 1;
    cursor: pointer;
  }

  .userProfileImageDefaultLabelField {
    object-fit: contain;
    cursor: pointer;
  }

  .userProfileImageLabelField:hover {
    z-index: 3;
    visibility: visible;
  }
}
