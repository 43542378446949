.multi-select {
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
  width: 815px;
  margin: auto;
}

.multi-select .option {
  box-sizing: border-box;
  display: flex;
  border-radius: 24px;
  cursor: pointer;
  height: 40px;
  padding: 0 15px;
  align-items: center;
  margin: 0 5px 10px 5px;
  color: #50545c;
  border: 2px solid #a1a9b8;
  transition: border 0.2s linear, color 0.2s linear;
  background-color: white;
}

.multi-select .option:hover {
  border: 2px solid #292e4c;
  color: #292e4c;
}

.multi-select .option.selected {
  border: 2px solid #cf4642;
  color: #292e4c;
}

.multi-select .option > span {
  flex: 1;
  padding-right: 8px;
}
