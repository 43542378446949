.videoImage {
  grid-area: image;
  width: 206px !important;
  height: 116px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.thumbnailTimestamp {
  grid-area: thumbnailTimestamp;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.videoTitle {
  grid-area: title;
  /* display:flex;
  flex-direction:column;
  justify-content:flex-end */
}

.videoSubject {
  grid-area: subject;
}

.videoType {
  grid-area: type;
}

.videoCategory {
  grid-area: category;
}

.videoDescription {
  grid-area: description;
}

.videoTranscript {
  grid-area: transcript;
}

.videoRecommended {
  grid-area: recommended;
}

.videoPublic {
  grid-area: public;
}

@media (min-width: 300px) {
  .videoEditorInputsContainer {
    grid-template-columns: 1fr;
    grid-template-areas:
      "image"
      "title"
      "subject"
      "type"
      "category"
      "recommended"
      "public";
  }
}

@media (min-width: 768px) {
  .videoImage {
    margin-bottom: -80px;
  }

  .videoEditorInputsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    grid-template-areas:
      "image thumbnailTimestamp"
      "title title"
      "subject subject"
      "type type"
      "category category"
      "description description"
      "transcript transcript"
      "recommended ."
      "public .";
  }
}
