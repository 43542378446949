@media (max-width: 500px) {
  .longDescriptionContainer {
    /* margin-left: 10px;
    margin-right: 10px; */
  }
}

@media (min-width: 500px) {
  .longDescriptionContainer {
    margin-left: 0px;
    margin-right: 0px;
  }
}
