.top-three-goals .title {
  font-family: "Lato-Regular";
  font-size: 20px;
}

.top-three-goals .goal-options-container {
  margin-bottom: 30px;
}

.top-three-goals .goal-option {
  padding: 14px 0;
}

.top-three-goals .goal-option > span {
  font-family: Lato-Regular;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #292e4c;
  padding: 12px 22px;
  background-color: white;
}
