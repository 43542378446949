@media (max-width: 600px) {
    .buttonSelectionLayout{
        padding:46px 10px;
        overflow: visible;
    }
}

@media (min-width: 600px) {
    .buttonSelectionLayout{
        padding:46px 100px;
        overflow: visible;
        padding-top:0px;
    }
}
