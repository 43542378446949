.member-card .militaryOccupationLink {
  text-decoration: none;
  color: #e1373a;
}

.member-card .militaryOccupationLink:hover {
  color: #e1373a;
}

.member-card .footerButton:hover {
  cursor: pointer;
  border-bottom: 1px solid #e1373a;
}

.member-card .mentor-badge {
  margin: 0;
  background: rgb(41, 46, 76);
  color: white;
  padding: 0.3em;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: initial;
}

.member-card .mentor-badge:before,
.member-card .mentor-badge:after {
  content: "";
  position: absolute;
  top: 0;
  margin: 0 -1px;
  width: 100%;
  height: 100%;
  background: rgb(41, 46, 76);
}

.member-card .mentor-badge:before {
  right: 100%;
}

.member-card .mentor-badge:after {
  left: 100%;
}
