.spouse-course-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 126px;
  background-color: #f5f6f8;
  padding-left: 8%;
  padding-right: 8%;
}

.spouse-course-sections-container {
  display: flex;
  margin: 25px 0;
}

.page-title {
  font-family: "Lato-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}

.page-title span {
  color: rgba(3, 14, 37, 0.4);
}

.spouse-course-sections-container .left-section {
  flex: 1 1 30%;
  flex-direction: column;
}

.spouse-course-sections-container .right-section {
  flex: 1 1 70%;
  display: flex;
  flex-direction: column;
}

.spouse-course-section-title {
  color: #060f21;
  font-family: "Lato-bold";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 22px;
  margin: 8px 0px;
}

.spouse-course-container .left-section .spouse-course-section-content {
  margin-bottom: 16px;
  box-shadow: 2px 3px 12px rgb(0 0 0 / 5%);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .spouse-course-sections-container {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    padding-left: 2em;
    padding-right: 2em;
  }

  .spouse-course-sections-container .left-section {
    flex: 1 1 100%;
    order: 2;
  }

  .spouse-course-sections-container .right-section {
    padding-left: 0;
    flex: 1 1 100%;
  }

  .spouse-course-sections-container .card-action-container {
    padding: 0px 0px 32px 0px !important;
  }

  .event-container,
  .CohortListContainer {
    margin-right: 0px;
    padding-bottom: 32px;
  }

  .webinar-container,
  .activities-container,
  .event-container {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 650px) {
  .spouse-course-container {
    padding: 10px 0 0 0;
  }

  .spouse-course-container .page-title{
    margin: 80px 16px 0;
  }

  .spouse-course-sections-container {
    padding: 20px 16px 0;
  }

  .spouse-course-section-title {
    font-family: "Lato-Bold";
    font-style: normal;
    font-size: 22px;
    line-height: 26px;
    color: #060f21;
    margin: 8px 0px;
  }

  .webinar-video-container .video-description {
    margin-top: 25px;
  }

  .activity-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .activity-container .activity-logo {
    margin: 10px 10px 18px 0px;
  }

  .activity-container .activity-info {
    margin-bottom: 20px;
    max-width: 100%;
    padding-right: 0px;
  }

  .activity-title {
    font-size: 20px;
  }

  .activity-description {
    font-size: 18px;
  }

  .activity-button {
    font-family: "Lato-Light";
    width: 100%;
    font-size: 18px;
  }
}
