@media (max-width: 600px) {

  .pypSplashModuleContainer{
    height: fit-content;
    min-height:100vh
  }

    .pypTitleContentWrapper {
      width:100%
    }
  }

  @media (min-width: 600px) {
    .pypTitleContentWrapper {
      width:100%
    }

    .pypSplashModuleContainer{
      min-height: calc(100vh - 60px);
      height: fit-content;
    }

    .pypFooterContent{
      padding-right: 12px;
      padding-left: 12px;
    }
  }
