.nickname {
  grid-area: nickname;
}

.dateOfBirth {
  grid-area: dateOfBirth;
}

.phoneNumber {
  grid-area: phoneNumber;
}

.gender {
  grid-area: gender;
}

.ethnicity {
  grid-area: ethnicity;
}

.maritalStatus {
  grid-area: maritalStatus;
}

.howDidYouHear {
  grid-area: howDidYouHear;
}

.linkedInUrl {
  grid-area: linkedInUrl;
}

@media (min-width: 325px) {
  .howDidYouHearDescription {
    margin-top: -16px;
  }

  .personalInfoContainer {
    grid-template-columns: 1fr;
    grid-template-areas:
      "nickname"
      "dateOfBirth"
      "phoneNumber"
      "gender"
      "ethnicity"
      "maritalStatus"
      "howDidYouhear"
      "linkedInUrl";
  }
}

@media (min-width: 768px) {
  .howDidYouHearDescription {
    margin-top: 0px;
  }

  .personalInfoContainer {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "nickname dateOfBirth"
      "phoneNumber gender"
      "ethnicity maritalStatus"
      "howDidYouHear linkedInUrl";
  }
}

input:checked:after {
  position: absolute;
  content: "";
  background-color: transparent;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 10px solid #292e4c;
  left: -7px;
  top: -7px;
}

/* .description-container {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  text-align: center;
} */
.main-parent {
  padding: 35px;
}
@media (max-width: 476px) {
  .hidden-xs {
    display: none;
  }
}