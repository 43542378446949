@media (min-width: 740px) {
  .status-text {
    width: 600px;
  }
}

@media (max-width: 600px) {
  .status-text {
    width: 100%;
  }
}