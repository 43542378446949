@media (max-width: 850px) {
  .pypDescriptionContainer {
    line-height:34px
  }
}

@media (min-width: 850px) {
    .pypDescriptionContainer {
      line-height:27px
    }
  }