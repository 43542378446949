@media (max-width: 768px) {
  .applicationEditorContainer {
    margin-bottom: 50px;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }

  .applicationWrapper {
    margin-top: 60px;
  }

  .applicationProcessContainer {
  }
}

@media (min-width: 600px) {
  .applicationWrapper {
    height: 100%;
    margin-top: 0px;
  }

  .applicationProcessContainer {
    overflow: auto;
    height: 100vh;
  }
}

/* 830px is where the sidebar dissapears, so only set min-width to
80% when screen width is > 830px */
@media (min-width: 769px) {
  .applicationEditorContainer {
    min-width: 80%;
  }
}
