.nonDodIcMilitaryContainer .department {
  grid-area: department;
}

.nonDodIcMilitaryContainer .gsLevel {
  grid-area: gsLevel;
}

.nonDodIcMilitaryContainer .employmentStatus {
  grid-area: employmentStatus;
}

.nonDodIcMilitaryContainer .lastEmployment {
  grid-area: lastEmployment;
}

.nonDodIcMilitaryContainer .sepDate {
  grid-area: sepDate;
}

.nonDodIcMilitaryContainer .yearsOfService {
  grid-area: yearsOfService;
}

.nonDodIcMilitaryContainer .militaryOccupation {
  grid-area: militaryOccupation;
}

/* Move 'Did you serve in the Military?' to the end */
.nonDodIcMilitaryContainer .didServe {
  grid-area: didServe;
}

/* Conditional fields (appear at the bottom only if 'Yes' is selected) */
.nonDodIcMilitaryContainer .branch {
  grid-area: branch;
}

.nonDodIcMilitaryContainer .rank {
  grid-area: rank;
}

.nonDodIcMilitaryContainer .status {
  grid-area: status;
}

.nonDodIcMilitaryContainer .sofSelect {
  grid-area: sofSelect;
}

.nonDodIcMilitaryContainer .sofUnit {
  grid-area: sofUnit;
}

/* Ensure conditional fields are hidden by default */
.nonDodIcMilitaryContainer .militaryOccupation.invisible,
.nonDodIcMilitaryContainer .branch.invisible,
.nonDodIcMilitaryContainer .rank.invisible,
.nonDodIcMilitaryContainer .status.invisible,
.nonDodIcMilitaryContainer .sofUnit.invisible {
  display: none;
}

/* Updated grid layout */
.nonDodIcMilitaryContainer {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "department gsLevel"
    "employmentStatus lastEmployment"
    "sepDate yearsOfService"
    "didServe branch" /* 'Branch' now aligns directly beside 'Did you serve?'. */
    "status rank"
    "militaryOccupation sofSelect"
    "sofUnit sofUnit";
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .nonDodIcMilitaryContainer {
    display: block;
    padding-bottom: 40px;
  }
}