.alliedForcesMilitaryContainer .status {
    grid-area: status;
  }
  
  .alliedForcesMilitaryContainer .sepDate {
    grid-area: sepDate;
  }
  
  .alliedForcesMilitaryContainer .branch {
    grid-area: branch;
  }
  
  .alliedForcesMilitaryContainer .rank {
    grid-area: rank;
  }
  
  .alliedForcesMilitaryContainer .duty {
    grid-area: duty;
  }
  
  .alliedForcesMilitaryContainer .yearsOfService {
    grid-area: yearsOfService;
  }
  
  .alliedForcesMilitaryContainer .specialty {
    grid-area: specialty;
  }
  
  .alliedForcesMilitaryContainer .sofSelect {
    grid-area: sofSelect;
  }
  
  .alliedForcesMilitaryContainer .sofUnit {
    grid-area: sofUnit;
  }
  
  .alliedForcesMilitaryContainer .sofUnit.invisible {
    display: none;
  }
  
  .alliedForcesMilitaryContainer {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "branch rank"
      "status yearsOfService"
      "sepDate specialty"
      "duty sofSelect"
      "sofUnit .";
  }
  
  @media (max-width: 768px) {
    .alliedForcesMilitaryContainer {
      display: block;
      padding-bottom: 40px;
    }
  }
  