.likert-input {
  font-size: 18px;
  font-family: "Lato-Regular";
  max-width: 900px;
  margin: auto;
  margin-bottom: 60px;
}

.likert-input .title {
  display: block;
  margin-bottom: 30px;
}

.likert-input .options {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.likert-input .options:after {
  content: "";
  background-color: #a1a9b8;
  height: 3px;
  position: absolute;
  width: 100%;
}

.likert-input .options input {
  position: relative;
  appearance: none;
  background-color: #f4f4f8;
  width: 20px;
  height: 20px;
  border-radius: 15px;
  border: 2px solid #a1a9b8;
  z-index: 1;
}

.likert-input .options input:checked:after {
  position: absolute;
  content: "";
  background-color: transparent;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 10px solid #292e4c;
  left: -7px;
  top: -7px;
}

.description-container {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 14px;
  color: #a1a9b8;
}
