.navHoverWithUnderline:hover {
  height: calc(100% - 2px) !important;
  margin-top: 2px !important;
  border-bottom: 2.5px solid #e1373a !important;
}

.disabledNavItem {
  color: #ffffff;
  opacity: 0.75;
}

/* .disabledNavItem:hover {
  height: calc(100% - 2px) !important;
  margin-top: 2px !important;
  border-bottom: 2.5px solid #a5a5a5 !important; */
/* } */

.navDropDownSection {
  transition: all 0.3s ease-in-out 0.5s, visibility 0.5s linear 0.3s,
    z-index 0s linear 10ms, opacity 0.5s ease-in-out;
  transition-delay: 0s, 0s, 0.3s;
  transform-origin: top center;
}

.navDropDownSection > button {
  background: var(--white);
}

.navDropDownSection > button:hover {
  background-color: #f8f9fa;
}

.navItemWithDrop:hover > section {
  transition-delay: 0s, 0s, 0.3s;
  transform: scale(1);
  background-color: white;
  margin-top: 12px;
  opacity: 1;
  cursor: default;
}

.navItemWithDrop > section:after {
  position: absolute;
  top: -7px;
  left: 45%;
  background: #fff;
  height: 14px;
  box-shadow: -1px -1px 0px -1px rgba(0, 0, 0, 0.1),
    -1px -1px 3px rgba(0, 0, 0, 0.1);
  width: 14px;
  transform: rotate(45deg);
  content: "";
}

.navItemWithDrop > section:before {
  position: absolute;
  top: -25px;
  opacity: 0;
  background-color: purple;
  width: 161px;
  height: 25px;
  content: "";
  cursor: default;
}
