@media only screen and (min-width: 651px) {
  .notesTitle{
        padding-bottom:50px;
  }

  .notesContentWrapper{
    margin-top:50px;
  }

}

@media only screen and (max-width: 650px) {

  .notesTitle{
    font-size:23px;
    padding-bottom:20px;
  }

  .notesContentWrapper{
    margin-top:20px;
  }

}