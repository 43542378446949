@media (max-width: 649px) {
  .videoTitleBarContainer {
    height: fit-content;
    flex-direction: column;
    align-items: unset;
    min-height: 110px;
  }

  .videoControlContainer {
    margin-top: 20px;
    justify-content: space-between;
  }
}

@media (min-width: 650px) {
  .videoTitleBarContainer {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    flex-direction: row;
  }

  .videoControlContainer {
    align-self: flex-start;
  }
}
