/* overwriting bootstrap */

.form-control:focus{
  border:none !important;
  border-color:#fff !important;
  box-shadow:none !important;
  
}

.form-control{
  border:none !important;
  font-family:Lato-Regular
}

.searchInput::placeholder{
  color:#030d26;
}

@media (max-width: 500px) {
  .searchbarContainer{
    width:300px;
  }
}

@media (min-width: 500px) {
 .searchbarContainer{
   width:380px;
 }
}