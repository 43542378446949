.profileProfessionalContainer .currentJob {
  grid-area: currentjob;
  place-self: center;
  width: 80% !important;
}

.profileProfessionalContainer .linkedInUrlProfile {
  grid-area: linkedinurlProfile;
  place-self: center;
  width: 80% !important;
}

.profileProfessionalContainer .community {
  grid-area: community;
  place-self: center;
  width: 80% !important;
}

@media (min-width: 325px) {
  .profileProfessionalContainer {
    grid-template-columns: 1fr;
    grid-template-areas:
      "currentjob"
      "linkedinurlProfile"
      "community";
  }
  .profileTwoProfessionalContainer {
    grid-template-columns: 1fr 1fr;
  }
  .currentJob {
    width: 100% !important;
  }

  .linkedInUrlProfile {
    width: 100% !important;
  }

  .community {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .profileProfessionalContainer {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "currentjob"
      "linkedinurlProfile"
      "community";
  }
  .profileTwoProfessionalContainer {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
}
