.progress-bar-container {
  height: 28px;
  width: 45%;
  left: 290px;
  top: 216px;
  border-radius: 0px;
  text-align: center;
}

.progress-bar-container span {
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.progress-bar {
  width: 100%;
  height: 5px;
  background-color: #eff1f2;
  border-radius: 31px;
}

.progress-bar-fill {
  display: block;
  height: 5px;
  background-color: #030e25;
  border-radius: 31px;
  transition: width 500ms ease-in-out;
}

@media only screen and (max-width: 650px) {
  .progress-bar-container {
    margin: 35px 0px;
  }
}
