@media (max-width: 850px) {
    .pypTitle{
      font-size:1.9rem
    }
  }

  @media (min-width: 850px) {
      .pypTitle {
        font-size:40px
      }
    }


.pypTitle {

}
