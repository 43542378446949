
.firstNameProfile {
  grid-area: firstNameProfile;
}

.lastNameProfile {
  grid-area: lastNameProfile;
}

.emailProfile {
  grid-area: emailProfile;
}

.linkedInProfile {
  grid-area: linkedInProfile;
}

.nicknameProfile {
  grid-area: nicknameProfile;
}

.phoneNumberProfile {
  grid-area: phoneNumberProfile;
}


@media (min-width: 425px) {
  .profilePersonalContainer {
    grid-template-columns: 1fr;
    grid-template-areas:
      "firstNameProfile"
      "lastNameProfile"
      "emailProfile"
      "nicknameProfile"
      "linkedInProfile"
      "phoneNumberProfile";
  }
}

@media (min-width: 768px) {
  .profilePersonalContainer {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "firstNameProfile nicknameProfile"
      "lastNameProfile linkedInProfile"
      "emailProfile phoneNumberProfile";
  }
}

@media (min-width: 992px) {
  .profilePersonalContainer {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "firstNameProfile nicknameProfile"
    "lastNameProfile linkedInProfile"
    "emailProfile phoneNumberProfile";
  }
}