.categoryHeaderContainer {
  width: 98%;
}

@media (max-width: 699px) {
  .categoryTitle {
    font-size: 26px;
    line-height: 26px;
  }

  .base {
    margin-bottom: 65px;
  }
  .categoryHeaderContainer {
    margin-bottom: 20px;
    justify-content: space-between;
  }
}

@media (min-width: 700px) {
  .categoryTitle {
    font-size: 20px;
    line-height: 2.63;
  }
  .base {
    margin-top: 0px;
  }

  .categoryHeaderContainer {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 1100px) {
  .categoryHeaderContainer {
    width: 808px;
  }
}

@media (min-width: 1362px) {
  .categoryHeaderContainer {
    width: 1075px;
  }
}

@media (min-width: 1670px) {
  .categoryHeaderContainer {
    width: 1345px;
  }
}
