.clickable:hover {
    cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

@media (max-width: 600px) {
    #carousel {
        margin-top: 100px !important;
        min-height: 420px !important;
        display: flex !important;
        flex-direction: column !important;
        width: 100% !important;
    }
}


@media (max-width: 1050px) {
    #carousel {
        width: 100%;
    }
}
