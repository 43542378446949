.userBiographyHeader {
  width: 100%;
  padding-bottom: 39px;
}

.userBiographyUsername {
  margin: 0 auto;
  font-family: Lato-Black;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  padding-bottom: 5px;
}

.userBiographyMilitary {
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
}

.userBiographyContainer {
  height: 100px;
  max-height: 100px;
  padding: 0 28px;
  padding-bottom: 50px;
}

.userBiographyTextClamped {
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  display: -webkit-box;
  color: #ffffff;
  height: 88px;
  max-height: 88px;
}

.userBiographyReadMore {
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  border: none;
  background: none;
  margin-top: 5px;
  grid-area: userBiographyReadMore;
  width: 78px;
  height: 28px;
  font-family: Lato-SemiBold;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.52px;
  color: #e1373a;
  white-space: nowrap;
}

.userBiographyRedirect {
  height: 40px;
  margin-top: 17px;
  padding-top: 5em;
}

.separation-date {
  font-family: Lato-Regular;
  padding-top: 1em;
}

.separation-date strong {
  font-family: Lato-Bold;
}

.userBiographyReadMore:focus {
  outline: none;
}

@media only screen and (max-width: 650px) {
  .userBiographyHeader {
    margin-right: 20px;
    margin-top: 36px;
  }
  .userBiographyMilitary {
    font-family: Lato-Regular;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
  }

  .userBiographyTextClamped {
    height: fit-content;
    font-size: 14px;
    line-height: 17px;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }

  .userBiographyReadMore {
    margin-left: 27px;
  }

  .userBiographyUsername {
    margin: 0;
    width: fit-content;
    font-weight: Lato-Black;
    letter-spacing: 0.66px;
    font-size: 18px;
    line-height: 22px;
  }

  .userBiographyContainer {
    height: 85px;
    max-height: 85px;
    padding: 10px 0;
  }

  .separation-date {
    padding-top: 0;
    font-size: 14px;
    line-height: 17px;
  }

  .userBiographyRedirect {
    height: 100%;
    padding-top: 25px;
    text-align: left;
  }
}
