.formulary-container {
  width: 80%;
  margin: auto;
  margin-top: 50px;
}

.formulary-empty-inputs-container {
  font-size: 22px;
  text-align: center;
}

.formulary-empty-inputs-container p {
  font-size: 16px;
  margin-top: 8px;
}

@media (max-width: 768px) {
  .formulary-container {
    padding-bottom: 40px;
  }
}
