@media (min-width: 740px) {
 .commitFormContainer{
   width:42.8% ;
   min-width:600px;
   padding-left: 75px;
   padding-right: 75px;
 }
}

@media (max-width: 739px){
  .commitFormContainer{
    width: calc(100vw - 30px) !important;
    padding-left: 25px;
    padding-right: 25px;
  }
}
