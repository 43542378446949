@media (min-width: 768px){
  .navContainer{
    max-width: 95%;
  }
}

@media (min-width: 992px){
  .navContainer{
    max-width:960px
  }
}

@media (min-width: 1200px){
  .navContainer{
    max-width:1140px
  }
}

.donate-btn{
  background-color:#fff
}

.donate-btn:hover{
  background-color:#dae0e5;
  transition: background-color 0.5s, color 0.5s;
}

.apply-btn{
  background-color:#030d26;
  color:#fff;
}

.apply-btn:hover{
  background-color:#fff;
  color:#030d26;
  transition: background-color 0.5s, color 0.5s
  
}