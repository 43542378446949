.streetAddress {
    grid-area: streetAddress;
  }
  
  .city {
    grid-area: city;
  }
  
  .state {
    grid-area: state;
  }
  
  .zip {
    grid-area: zip;
  }
  
  .willLocate {
    grid-area: willLocate;
  }
  
  @media (min-width: 425px) {
    .singleGridContainer {
      grid-template-columns: 1fr;
      grid-template-areas:
        "streetAddress"
        "city"
        "state"
        "zip"
        "willLocate";
    }
  }
  
  @media (min-width: 768px) {
    .singleGridContainer {
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: 1fr;
      grid-template-areas:
        "streetAddress city"
        "state zip"
        "willLocate willLocate";
    }
  }
  
  @media (min-width: 992px) {
    .singleGridContainer {
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: 1fr;
      grid-template-areas:
        "streetAddress city"
        "state zip"
        "willLocate willLocate";
    }
  }

  