.activities-section-description {
  font-family: lato-regular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #545158;
  margin-bottom: 12px;
}

.activity-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background: #f5f6f8;
  border-radius: 4px;
  margin-bottom: 24px;
  width: 100%;
}

.activity-logo {
  margin: 0 22px 0 10px;
  width: 20px;
  height: 20px;
}

.activity-info {
  max-width: 70%;
  min-width: 60%;
  flex-grow: 1;
  padding-right: 10px;
  width: inherit;
}

.activity-title {
  font-family: Lato-Bold;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #060f21;
  margin: 4px 0px;
}

.activity-description {
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #545158;
  margin: 4px 0px;
  line-height: 20px;
}

.activity-button {
  width: 168px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background: #2f539d;
  border-radius: 5px;
  color: #ffffff;
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  text-transform: capitalize;
}
.activity-button:hover {
  color: #ffffff;
  text-decoration: none;
}

.activities-list-container {
  width: 100%;
  position: relative;
}

.activities-list-container .completed-column-title {
  font-family: Lato-Regular;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  color: #545158;
}

.activities-list-container .completed-column-title.completed {
  color: #060f21;
}

.activities-list .activity-completed-checkbox-container {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 57px;
}

.show-more-collapse {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  font-family: "Lato-Regular";
  color: #2f539d;
}

.pre-session {
  margin-bottom: 24px;
}

.activities-container {
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
