.content {
  grid-area: content;
}

.nav {
  grid-area: nav;
}

.contentContainer {
  height: 100%;
  width: 100%;
  overflow: auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    "nav"
    "content";
}

@media (min-width: 300px) {
  .contentContainer {
    position: fixed;
    height: 100%;
  }
}

@media (min-width: 769px) {
  .contentContainer {
    position: unset;
  }
}
