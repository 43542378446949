.user-action-card-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  min-width: 260px;
}

.biography-button {
  padding-left: 0;
}

.spouse-action-links .biography-button:hover {
  text-decoration: underline;
}

.profile-section {
  display: flex;
}

.user-profile-image-container {
  flex: 1 1 30%;
  text-align: center;
  margin-bottom: 16px;
  max-width: fit-content;
}

.user-profile-image {
  border-radius: 100px;
}

.user-info-section {
  flex: 1 1 70%;
  padding-left: 6%;
}

.user-info-title {
  font-family: Lato-Bold;
  font-style: normal;
  font-size: 16px;
  line-height: 29px;
  color: #030e25;
}

.user-info-subtitle {
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #c3c7ca;
  margin-bottom: 10px;
}

.user-info-email {
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: 500  ;
  font-size: 16px;
  line-height: 19px;
  color: #2F539D;;
  margin-bottom: 20px;
  margin-right: 20px;
}

.action-links {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 11px 0;
  border-top: 1px solid #f2f2f2;
  margin-right: 30px;
}

.spouse-action-links {
  justify-content: space-evenly;
  padding: 20px 0 11px 0;
}

.action-links a,
.action-links button {
  font-family: Lato-Bold;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #e1373a;
  border: none;
  background-color: transparent;
  z-index: 12;
}

.spouse-action-links a,
.spouse-action-links button {
  /* font-family: Lato-regular;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: left;
  color: #e1373a;
  border: none;
  background-color: transparent;
  z-index: 12; */

  font-family: Lato-Bold;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #e1373a;
  border: none;
  background-color: transparent;
  z-index: 12;
}

.action-links::after {
  content: "•";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #E0E0E0;
}

.spouse-action-links::after {
  content: "•";
  position: absolute;
  top: 10px;
  right: 0;
  bottom: 0;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #E0E0E0;
}

.disabled {
  opacity: 0.6;
}

.action-buttons {
  display: flex;
  flex-direction: column;
}

.action-buttons button {
  margin: 5px 0;
}

.action-buttons a.email-button {
  width: 100%;
  min-height: 44px;
  cursor: pointer;
  border: 2px solid rgb(225, 55, 58);
  font-family: Lato-Regular;
  padding: 10px;
  font-size: 14px;
  line-height: 1.21;
  white-space: nowrap;
  transition: all 0.3s ease-in-out 0s;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: center;
}

.user-profile-image {
  height: 82px;
  width: 82px;
}

@media only screen and (max-width: 650px) {
  .user-action-card-container {
    padding: 14px;
  }

  .action-links {
    justify-content: space-around;
    margin-top: 1em;
  }

  .user-info-subtitle {
    margin-bottom: 0px;
  }

  .user-profile-image-container {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .user-info-email {
    font-size: 12px;
  }
}

@media only screen and (max-width: 425px) {
  .user-info-email {
    font-size: 12px;
  }
}