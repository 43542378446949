@media (max-width: 600px) {
    .pypInstructionDescription {
        width: 90%
    }
  }
  
  @media (min-width: 600px) {
      .pypInstructionDescription {
        width:500px
      }
    }
    