
@media (min-width: 992px) {
 .videoThumbnailContainer{
     width: 216px;
 }
}

@media (max-width: 991px) {
 .videoThumbnailContainer{
     width:50%;
 }
}


@media (max-width: 500px) {
 .videoContainer{
   width: 100%
 }

 .videoThumbnailContainer{
     width: 100%;
     margin-bottom: 20px;
 }
}
