.nps-input {
  font-size: 18px;
  max-width: 700px;
  margin: auto;
  margin-bottom: 60px;
}

.nps-input .options-container {
  display: flex;
  margin-top: 30px;
  justify-content: space-around;
}

.nps-input .options-container button {
  width: 56px;
  height: 46px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #a1a9b8;
  transition: border 0.3s ease-in;
}

.nps-input .options-container button:hover {
  border: 1px solid #292e4c;
}

.nps-input .options-container .selected {
  background-color: #292e4c;
  color: #fff;
}
