.video {
  grid-area: video;
}
.videoContentContainer {
  grid-area: videoContentContainer;
}
.videoMetaContent {
  grid-area: videoMetaContent;
  padding: 0px 2vw 20px 2vw;
}
.thumbnailContainer {
  grid-area: thumbnailContainer;
}

@media (min-width: 501px) {
  .thumbnailContainerTitle {
    margin-left: 4vw;
  }
}


@media (min-width: 768px) {
  .focusedVideoComponentContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "videoContentContainer"
      "thumbnailContainer";
  }

  .thumbnailContainer {
    align-items: flex-start;
    padding: 2vw;
  }

  .thumbnailContainerTitle {
    margin-left: 0px;
  }
}


@media (max-width: 500px) {
  .focusedVideoComponentContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "videoContentContainer"
      "thumbnailContainer";
  }

  .videoMetaContent {
    padding: 0px 4vw;
  }

  .thumbnailContainerTitle {
    margin-top: 50px;
    margin-left: 4vw;
  }
}

@media (min-width: 1100px) {
  .focusedVideoComponentContainer {
    height: calc(100vh - 60px);
    display: grid;
    /* grid-column-gap: 20px; */
    grid-template-columns: 3fr 1fr;
    grid-template-areas: "videoContentContainer thumbnailContainer";
  }

  .thumbnailContainer {
    align-items: center;
    padding: 0px;
  }

  .videoMetaContent {
    padding: 0px;
  }

  .videoContentContainer {
    margin: 2vw;
  }

  /* .thumbnailContainer {
    overflow-y: scroll;
  } */

  /* .videoContentContainer {
    margin: 20px;
    margin-top:50px;
    margin-right:0px;
  } */
}

.video-react {
  color: #d8f0f8 !important;
}

.focusedVideoComponentContainer .video-react .video-react-big-play-button {
  background-color: #030d26 !important;
  border: 0.06666em solid#d8f0f8 !important;
  color: #d8f0f8 !important;
}

.focusedVideoComponentContainer .video-react .video-react-control-bar {
  background-color: #030d26 !important;
  color: #d8f0f8 !important;
}

.focusedVideoComponentContainer .video-react .video-react-play-progress {
  background-color: #d8f0f8 !important;
}

.focusedVideoComponentContainer .videoExpansionPanel:before {
  display: none;
}
