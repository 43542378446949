.nickname {
    grid-area: nickname;
}

.dateOfBirth {
    grid-area: dateOfBirth;
}

.phoneNumber {
    grid-area: phoneNumber;
}

.gender {
    grid-area: gender;
}

.ethnicity {
    grid-area: ethnicity;
}

.maritalStatus {
    grid-area: maritalStatus;
}

.howDidYouHear {
    grid-area: howDidYouHear;
}

.linkedInUrl {
    grid-area: linkedInUrl;
}

@media (min-width: 325px) {
    .howDidYouHearDescription {
        margin-top: -16px;
    }

    .alliedForcesPersonalInfoContainer { /* Updated for Allied Forces context */
        grid-template-columns: 1fr;
        grid-template-areas:
            "nickname"
            "dateOfBirth"
            "phoneNumber"
            "gender"
            "ethnicity"
            "maritalStatus"
            "howDidYouHear"
            "linkedInUrl";
    }
}

@media (min-width: 768px) {
    .howDidYouHearDescription {
        margin-top: 0px;
    }

    .alliedForcesPersonalInfoContainer { /* Updated for Allied Forces context */
        display: grid;
        grid-column-gap: 20px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "nickname dateOfBirth"
            "phoneNumber gender"
            "ethnicity maritalStatus"
            "howDidYouHear linkedInUrl";
    }
}
