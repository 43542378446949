.CohortListContainer {
  min-height: 310px;
  background-color: white;
  color: #ffffff;
  margin-right: 32px;
}

.cohortListContainer {
  display: flex;
  flex-direction: row;
	flex-wrap: wrap;
  padding: 24px 20px;
}

.workshopItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 28px;
}

.workshopBannerLoader {
  min-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
}

.workshopBannerDivider {
  height: 1px;
  background-color: #16348880;
  margin-top: 28px;
  width: 65%;
  border-radius: 1px;
}

.member-card-active {
	height: 310px;
	color: #060F21;
	overflow: scroll;
}
.member-card-active .back-button-container { 
	display: flex;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	padding: 20px 20px 5px;
}

.member-card-active .back-button:hover {
  text-decoration: underline;
}
.member-card-active .back-button {
	font-family: Lato-regular;
	font-size: 16px;
	line-height: 17px;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	text-align: left;
	color: #e1373a;
	border: none;
	background-color: transparent;
	z-index: 12;
	margin-left: 10px;
}

.member-card-active .member-picture {
	display: flex;
  justify-content: center;
	height: 70px;
}

.member-card-active .member-picture img {
	border: 4px solid #E1373A;
	border-radius: 50%;
}

.member-card-active .member-name {
	font-family: Lato-Bold;
	font-weight: bold;
	font-size: 19px;
	line-height: 19px;
	text-align: center;
	margin-top: 5px;
}

.member-card-active .member-location {
	font-family: Lato-Regular;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	margin: 10px 0;
	color: #777F8D;
}

.member-card-active .member-profession {
	font-family: Lato-Regular;
	font-size: 15px;
	line-height: 19px;
	text-align: center;
	width: 60%;
  margin: 10px auto;
}

.member-card-active .member-military {
	font-family: Lato-Bold;
	font-size: 15px;
	line-height: 19px;
	text-align: center;
	width: 80%;
  margin: 10px auto;
}

.member-card-active .action-links {
	border: none;
	padding-top: 10px;
}
.network-link-container {
	border-top: 1px solid #C4C4C4;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	margin-bottom: 32px;
}

.network-link {
	
	border-radius: 5px;
	border: 1px solid #E1373A;
	display: flex;
	justify-content: center;
}