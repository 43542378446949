.layoutContainer {
  margin: 0px 100px;
}
.pypPartTitle {
  margin: 0px 75px 20px 75px;

}

@media (max-width: 600px) {
  .layoutContainer {
    margin: 85px 10px 8.5vh 10px;
  }
  .pypPartTitle {
    margin: 0px 20px 20px 20px;
    min-height: 200px;
  }
}

@media (max-width: 500px) {

  .pypPartTitle {
    min-height: 100px;
  }
}

@media (min-width: 600px) {
  .pypPartTitle {
    min-height: 230px;
  }
}
