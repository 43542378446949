.biographyPYPContainer {
  width: 100%;
  height: -webkit-fit-content;
  height: auto;
  min-height: 295px;
  border-radius: 5px;
  box-shadow: 12px 19px 35px -8px rgb(0 0 0 / 5%);
  background-color: #ffffff;
  margin: auto;
  color: #545158;
  font-style: normal;
  font-stretch: normal;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  display: flex;
  margin-bottom: 33px;
}

.PYP-title {
  font-size: 28px;
  font-family: Lato-Bold;
  padding-bottom: 25px;
}

.card-action-container {
  flex: 1 1 25%;
  padding-right: 23px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.biographyPYPText {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 33px;
  padding-bottom: 33px;
  padding-top: 33px;
  padding-right: 43px;
}

.coach-title {
  font-family: Lato-Bold;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
}

.biographyPYPDescription {
  color: #545158;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21.6px;
  height: fit-content;
  min-height: 58px;
  font-family: Lato-Regular;
  letter-spacing: normal;
  margin-bottom: 19px;
  display: -webkit-box;
  text-align: justify;
  width: 100%;
}

.biographyPYPTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  height: fit-content;
  min-height: 28px;
  font-family: Lato-SemiBold;
  letter-spacing: 0.62px;
  padding-bottom: 6px;
}

.bottom-section-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.biographyPYPResumeButton {
  font-family: Lato-SemiBold;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
}

@media only screen and (min-width: 651px) and (max-width: 1231px) {
  .biographyPYPContainer {
    height: 380px;
    max-height: 380px;
  }
}

@media only screen and (max-width: 650px) {
  .biographyPYPDescription {
    font-family: Lato-Regular;
    font-size: 14px;
    line-height: 17px;
    color: #545158;
    width: 100%;
    text-align: left;
    margin-bottom: 0;
  }
  .biographyPYPContainer {
    height: fit-content;
    background-position-x: center;
    background-position-y: top;
    display: flex;
    flex-direction: column;
  }

  .biographyPYPText {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 19px;
    padding-bottom: 19px;
    padding-top: 19px;
    padding-right: 17px;
  }

  .biographyPYPTitle {
    font-family: Lato-Bold;
    font-style: normal;
    font-size: 22px;
    line-height: 26px;
    color: #030e25;
  }

  button#biographyPYPRedirect {
    width: 100% !important;
  }

  .biographyPYPResumeButton {
    margin: auto;
    width: 80%;
    order: 2;
  }

  .card-action-container {
    padding: 16px;
    padding-bottom: 49px;
  }

  .bottom-section-container {
    flex-direction: column;
  }
}

.PYP-title {
  font-family: Lato-Bold;
  font-style: normal;
  font-size: 22px;
  line-height: 26px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 330px) {
  .biographyPYPTitle {
    height: 95px;
  }
}

.spouse-course-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 33px;
  padding-bottom: 33px;
  padding-top: 33px;
  padding-right: 43px;
}

.spouse-course-info-description {
  color: #545158;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21.6px;
  height: fit-content;
  min-height: 58px;
  font-family: Lato-Regular;
  letter-spacing: normal;
  margin-bottom: 19px;
  text-align: justify;
  width: 100%;
}