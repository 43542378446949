.layoutContainerResponsive {
  width: 100%;

  /* min-height: calc(100vh - 73px); */
  min-height: 100vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;

  /* Desktop-specific styles: */
  padding: 46px 0px 73px 0px;
  /* padding: 46px 0px 0px 0px; */
}


@media (max-width: 600px) {
  .layoutContainerResponsive {
    padding: 85px 0px 8.5vh 0px;
    overflow: auto;
    overflow-x: hidden;
  }
}
