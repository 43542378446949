/* @import url(https://fonts.googleapis.com/css?family=Lato&display=swap); */


body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

label {
  margin-bottom: 0px !important;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}
select {
  -webkit-appearance: none;
}

p {
  margin-bottom: 0px;
}


@font-face {
  font-family: Lato-Black;
  src: url(./Assets/Fonts/Lato-Black.ttf);
}

@font-face {
  font-family: Lato-Bold;
  src: url(./Assets/Fonts/Lato-Bold.ttf);
}

@font-face {
  font-family: Lato-BoldItalic;
  src: url(./Assets/Fonts/Lato-BoldItalic.ttf);
}

@font-face {
  font-family: Lato-Hairline;
  src: url(./Assets/Fonts/Lato-Hairline.ttf);
}

@font-face {
  font-family: Lato-HairlineItalic;
  src: url(./Assets/Fonts/Lato-HairlineItalic.ttf);
}

@font-face {
  font-family: Lato-Heavy;
  src: url(./Assets/Fonts/Lato-Heavy.ttf);
}

@font-face {
  font-family: Lato-HeavyItalic;
  src: url(./Assets/Fonts/Lato-HeavyItalic.ttf);
}

@font-face {
  font-family: Lato-Italic;
  src: url(./Assets/Fonts/Lato-Italic.ttf);
}

@font-face {
  font-family: Lato-Light;
  src: url(./Assets/Fonts/Lato-Light.ttf);
}

@font-face {
  font-family: Lato-LightItalic;
  src: url(./Assets/Fonts/Lato-LightItalic.ttf);
}

@font-face {
  font-family: Lato-Medium;
  src: url(./Assets/Fonts/Lato-Medium.ttf);
}

@font-face {
  font-family: Lato-MediumItalic;
  src: url(./Assets/Fonts/Lato-MediumItalic.ttf);
}

@font-face {
  font-family: Lato-SemiBold;
  src: url(./Assets/Fonts/Lato-Semibold.ttf);
}

@font-face {
  font-family: Lato-Regular;
  src: url(./Assets/Fonts/Lato-Regular.ttf);
} 

 
 @font-face {
  font-family: Lato-SemiBoldItalic;
  src: url(./Assets/Fonts/Lato-SemiboldItalic.ttf);
}

@font-face {
  font-family: Lato-Thin;
  src: url(./Assets/Fonts/Lato-Thin.ttf);
}

@font-face {
  font-family: Lato-ThinItalic;
  src: url(./Assets/Fonts/Lato-ThinItalic.ttf);
} 

