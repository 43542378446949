.militaryOneContainer .status {
  grid-area: status;
}

.militaryOneContainer .sepDate {
  grid-area: sepDate;
}

.militaryOneContainer .branch {
  grid-area: branch;
}

.militaryOneContainer .rank {
  grid-area: rank;
}

.militaryOneContainer .duty {
  grid-area: duty;
}

.militaryOneContainer .yearsOfService {
  grid-area: yearsOfService;
}

.militaryOneContainer .specialty {
  grid-area: specialty;
}

.militaryOneContainer .sofSelect {
  grid-area: sofSelect;
}

.militaryOneContainer .sofUnit {
  grid-area: sofUnit;
}

.militaryOneContainer .sofUnit.invisible {
  display: none;
}

.militaryOneContainer {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "branch rank"
    "status yearsOfService"
    "sepDate specialty"
    "duty sofSelect"
    "sofUnit .";
}

@media (max-width: 768px) {
  .militaryOneContainer {
    display: block;
    padding-bottom: 40px;
  }
}
