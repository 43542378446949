.custom-checkbox {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  font-family: "Lato-Regular";
}

.custom-checkbox.checked {
  border: 1px solid #292e4c;
}

.custom-checkbox input {
  position: absolute;
  appearance: none;
  background-color: #f3f3f3;
  border-radius: 0px 0px 5px 0px;
  border-right: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  height: 26px;
  width: 26px;
}

.custom-checkbox input:checked {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #292e4c;
}

.custom-checkbox input:focus {
  outline: 1px solid #292e4c;
  outline-offset: -3px;
}

.custom-checkbox input:checked:after {
  background-image: url("../../Assets/Images/check-mark.svg");
  background-repeat: no-repeat;
  display: block;
  content: " ";
  background-size: 14px 14px;
  height: 14px;
  width: 14px;
}

.custom-checkbox label {
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  padding-right: 16px;
  margin-left: 42px;
}
