.non-dod-ic-transition-goals-container {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
  }
  
  .non-dod-ic-transition-goals-container .checkBox {
    position: relative;
    width: 46%;
    margin: 1%;
  }
  
  @media (max-width: 768px) {
    .non-dod-ic-transition-goals-container {
      padding-bottom: 80px;
    }
  
    .non-dod-ic-transition-goals-container .checkBox {
      width: 100%;
      margin: 12px 0;
    }
  }
  