.sessions-container {
  display: flex;
  margin-bottom: 24px;
}

.sessions-container .session-name {
  margin-right: 24px;
  padding-bottom: 12px;
  border-bottom: 2px solid #545158;
}

.sessions-container .session-name,
.session-dropdown .session-name,
.MuiMenu-list .session-name {
  flex-grow: 1;
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #030e25;
  display: flex;
  align-items: center;
  justify-content: center;
}

.session-name.active-session,
.MuiMenu-list li.Mui-selected div.session-name,
.MuiSelect-select.MuiSelect-select div.session-name {
  color: #2f539d;
  font-weight: 800;
}

.sessions-container .session-name.active-session,
.MuiMenu-list li.Mui-selected {
  border-bottom: 4px solid #2f539d;
}

.session-name.disable-session {
  color: #8a8a8a;
  border-bottom: 2px solid #c4c4c4;
}

.session-dropdown .MuiSelect-select {
  background: #ffffff;
  border-radius: 9px;
  height: 50px;
  margin-bottom: 16px;
  color: #2f539d;
  font-family: "Lato-bold";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  display: flex;
}

.session-dropdown .MuiSelect-icon {
  height: 60px;
  width: 60px;
  color: #2f539d;
  top: calc(50% - 40px);
}

.sessions-container .new-session-text,
.session-dropdown .new-session-text,
.MuiMenu-list .new-session-text {
  background: #e1373a;
  border-radius: 4px;
  color: #ffffff;
  width: 37px;
  margin-left: 4.5px;
  height: 22px;
  font-size: 12px;
  font-family: "Lato-regular";
  font-weight: 600;
}

@media (max-width: 1024px) and (min-width: 768px) {
  .sessions-container .session-name {
    flex-direction: column-reverse;
  }

  .sessions-container .new-session-text {
    margin-bottom: 4px;
    margin-left: 0;
  }
}
