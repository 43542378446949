@media (max-width: 500px) {
  .videoArrayContainer {
    justify-content: center;
  }

  .thumbnailContainerTitle {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .thumbnailContainer {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 700px) {
  .thumbnailContainerVertical {
    max-width: 400px;
    border-left: solid 0.5px #e1e0e0;
  }

  .thumbnailTitleDescriptionWrapper, .thumbnailTitleDescriptionWrapperSidebar {
    max-width: 48%;
  }


}

@media (min-width: 1100px) {
  .thumbnailContainerList {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 650px) {
  .thumbnailTitleDescriptionWrapper {
    width: auto !important;
  }
}

@media (min-width: 1400px) {
  .thumbnailTitleDescriptionWrapper {
    max-width: 25%;
    width: 20%!important;
    margin: 0 10px;
  }
}