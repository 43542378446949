.nav > li {
  display: inline-block;
  padding: 1em 18px;
  cursor: pointer;
}
.drop {
  position: relative;
  transition: opacity 0.2s ease-in-out;
}
.drop .dropdown-0 {
  visibility: hidden;
  z-index: -2;
  position: absolute;
  right: 0%;
  opacity: 0;
  top: 65px;
  transition: all 0.3s ease-in-out 0.5s, visibility 0.5s linear 0.3s,
    z-index 0s linear 10ms, opacity 0.5s ease-in-out;
  transition-delay: 0s, 0s, 0.3s;
  transform-origin: top center;
  box-shadow: -1px -1px 4px -1px rgba(0, 0, 0, 0.2),
    2px 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding-left: 0;
  width: 210px;
  padding-bottom: 10px;
}
.drop .dropdown-2 {
  visibility: hidden;
  position: absolute;
  right: -50%;
  position: absolute;
  opacity: 0;
  top: 65px;
  transition: all 0.3s ease-in-out 0.5s, visibility 0.5s linear 0.3s,
    z-index 0s linear 10ms, opacity 0.5s ease-in-out;
  transition-delay: 0s, 0s, 0.3s;
  transform-origin: top center;
  box-shadow: -1px -1px 4px -1px rgba(0, 0, 0, 0.2),
    2px 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding-left: 0;
  width: 180px;
  padding-bottom: 10px;
}

.webDropdownItem:hover {
  background-color: #f8f9fa;
}

.drop .dropdown-3 {
  visibility: hidden;
  position: absolute;
  left: -43%;
  position: absolute;
  opacity: 0;
  top: 65px;
  transition: all 0.3s ease-in-out 0.5s, visibility 0.5s linear 0.3s,
    z-index 0s linear 10ms, opacity 0.5s ease-in-out;
  transition-delay: 0s, 0s, 0.3s;
  transform-origin: top center;
  box-shadow: -1px -1px 4px -1px rgba(0, 0, 0, 0.2),
    2px 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding-left: 0;
  width: 180px;
  padding-bottom: 10px;
}

.drop:hover ul {
  transition-delay: 0s, 0s, 0.3s;
  visibility: visible;
  transform: scale(1);
  background-color: white;
  margin-top: 12px;
  opacity: 1;
}

.drop ul:after {
  position: absolute;
  top: -7px;
  left: 45%;
  background: #fff;
  height: 14px;
  box-shadow: -1px -1px 0px -1px rgba(0, 0, 0, 0.1),
    -1px -1px 3px rgba(0, 0, 0, 0.1);
  width: 14px;
  transform: rotate(45deg);
  content: "";
}

.drop ul:before {
  position: absolute;
  top: -18px;
  right: 0%;
  opacity: 0;
  background-color: purple;
  width: inherit;
  height: 18px;
  content: "";
  cursor: default;
}

/* .nav > li {
  display: inline-block;
  padding: 1em 18px;
  cursor: pointer;
}
.drop {
  position: relative;
}
.drop .dropdown-0 {
  position: absolute;
  right: 0%;
  top: 3em;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  transform: scale(0);
  transform-origin: top center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  text-align: center;
  padding-left: 0;
  width: 180px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.drop .dropdown-2 {
  position: absolute;
  right: -50%;
  top: 3em;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: top center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  text-align: center;
  padding-left: 0;
  width: 180px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.drop .dropdown-3 {
  position: absolute;
  left: -43%;
  top: 3em;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: top center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  text-align: center;
  padding-left: 0;
  width: 180px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.drop ul li {
  display: block;
  width: 100%;
}
.drop ul li a {
  width: 100%;
  padding: 1em 18px;
  display: inline-block;
  white-space: pre;
  box-sizing: border-box;
  color: black;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  font-family: Lato;
}
.drop ul li a:hover {
  background: #ebebeb;
}
.drop:hover ul {
  transform: scale(1);
  background-color: white;
  margin-top: 12px
}
.drop ul:before {
  position: absolute;
  top: -7px;
  left: 45%;
  display: inline-block;
  border-right: 12px transparent transparent;
   border-bottom: 12px transparent transparent;
   border-left: 12px transparent transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
  background-color: transparent
}

.drop ul:after {
   position: absolute;
   top: -12px;
   left: 45%;
   display: inline-block;
   border-right: 12px solid transparent;
   border-bottom: 12px solid white;
   border-left: 12px solid transparent;
   content: '';
 } */
