@media (min-width: 600px) {
    .pypFooterContainer{
        height:73px
    }
  }
  @media (max-width: 600px) {
    .pypFooterContainer{

    }
  }
  
