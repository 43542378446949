@media (min-width: 300px) {
    .otherInterestContainer {
      padding-bottom: 50px;
    }
  }
  
  @media (min-width: 500px) {
    .otherInterestContainer {
      padding-bottom: 0px;
    }
  }
  