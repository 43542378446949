.dropdownItem:hover {
  color: #e1373a;
  background-color: #f8f9fa;
}

.dropdownItem {
  background-color: #fff;
  color: #212529;
}

.libraryHeaderContainer {
  grid-area: title;
}

.commitDropdown {
  grid-area: dropdown;
}

.commitSearchbar {
  grid-area: searchbar;
}

@media (min-width: 300px) {
  .videoLibraryHeaderContainer {
    margin-bottom: 20px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "dropdown";
  }
}

@media (max-width: 500px) {
  .libraryHeaderContainer {
    font-size: 1.2em !important;
  }
}

@media (max-width: 700px) {
  .videoLibraryHeaderContainer {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr;
    justify-items: center;
    grid-template-areas:
      "title"
      "dropdown";
  }

  .libraryHeaderContainer {
    margin-bottom:25px
  }
}

@media (min-width: 700px) {

  .libraryHeaderContainer {
    margin-bottom:25px
  }

  .videoLibraryHeaderContainer {
    display: grid;
    justify-items: center;
    grid-column-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "dropdown";
  }
}

@media (min-width: 980px) {
  .videoLibraryHeaderContainer {
    display: flex;
    width: 89%;
    align-items: center;
    justify-content: space-between;
  }

  .libraryHeaderContainer {
    margin-bottom:0.5rem
  }
}
