.burgeParentDiv > div > div > ul:after {
  position: absolute;
  top: -7px;
  left: 45%;
  background: #fff;
  height: 14px;
  box-shadow: -1px -1px 0px -1px rgba(0, 0, 0, 0.1),
    -1px -1px 3px rgba(0, 0, 0, 0.1);
  width: 14px;
  transform: rotate(45deg);
  content: "";
}

@media only screen and (width: 1100) {
  .layoutCalendarTitleContainer {
    text-align: center;
  }
}

@media only screen and (width: 600px) {
  .layoutCalendarbarGraph {
    min-height: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .layoutCalendarRectangle {
    height: 80px;
  }
}
