.video {
    grid-area: video;
  }
  .videoContentContainer {
    grid-area: videoContentContainer;
  }
  .videoMetaContent {
    grid-area: videoMetaContent;
    /* padding-left: 10px;
    padding-right: 10px; */
  }
  .thumbnailContainer {
    grid-area: thumbnailContainer;
  }

  /* @media (min-width: 425px) {
    .createAccountContainer {
      grid-template-columns: 1fr;
      grid-template-areas:
        "firstName"
        "lastName"
        "email"
        "password"
        "confirmPassword"
    }
  } */

  @media (min-width: 600px) {
    .pypVideoLayoutContainer{
      height:80%;
      width:100%;
      padding: 0 80px;
    }
  }

  /* On large screens, use 10% padding instead of hard-coded 80px */
  @media (min-width: 1200px) {
    .pypVideoLayoutContainer{
      padding: 0 10%;
    }
  }

  @media (max-width: 600px) {
    .pypVideoLayoutContainer{
      width:100%;
    }
  }

  /* Shrink the height/width of the video when the view height is shrunk */
  @media (max-height: 500px) {
    .pypVideoLayoutContainer{
      padding-left: calc(80px + 5vw);
      padding-right: calc(80px + 5vw);
    }
  }

  .video-react {
    color: #d8f0f8 !important;
  }

  .pypVideoLayoutContainer .video-react .video-react-big-play-button {
    background-color: #030d26 !important;
    border: 0.06666em solid#d8f0f8 !important;
    color: #d8f0f8 !important;
  }

  .pypVideoLayoutContainer .video-react .video-react-control-bar {
    background-color: #030d26 !important;
    color: #d8f0f8 !important;
  }

  .pypVideoLayoutContainer .video-react .video-react-play-progress {
    background-color: #d8f0f8 !important;
  }
