
 @media (min-width: 300px) {
    .appSectionTitle {
      font-size:30px
    }
  }
  
  
  @media (min-width: 500px) {
    .appSectionTitle {
      font-size:40px
    }
  }