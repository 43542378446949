.userBiographyGridContainer {
  display: flex;
  background: #030e25;
  flex-direction: column;
  text-align: center;
  border-radius: 5px;
  color: #ffffff;
  min-height: 669px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 730px) {
}

@media only screen and (max-width: 650px) {
  .userBiographyGridContainer {
    display: flex;
    background: #030e25;
    flex-direction: column;
    padding: 20px;
    border-radius: 5px;
    min-height: 273px;
    color: #ffffff;
    margin-bottom: 30px;
  }
}
