.biographyDigitalLibraryContainer {
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 12px 19px 35px -8px rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(159, 169, 186, 0.2);
}

.biographyDigitalLibraryApprovalContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.biographyDigitalLibraryApprovalTextContainer {
  margin-top: 65px;
  margin-right: 68px;
  flex: auto;
  width: min-content;
}

.biographyDigitalLibraryApprovalImage {
  margin: 43px 73px 45px 49px;
  float: left;
  width: 360px;
  height: 202px;
  flex: initial;
}

.biographyDigitalLibraryApproved {
  width: 250px;
  height: 28px;
  font-family: Lato-SemiBold;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.91;
  letter-spacing: 0.76px;
  color: #030d26;
}

.biographyDigitalLibraryApprovalMessage {
  width: 100%;
  min-width: 250px;
  height: 87px;
  font-family: Lato-Regular;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.3px;
  color: #292e4c;
}

.biographyDigitalLibraryApprovalButton {
  width: 147px;
  height: 40px;
  border-radius: 2px;
  margin-top: 5px;
  background-color: #e1373a;
  justify-content: center;
  align-items: center;
}

.biographyDigitalLibraryApprovalButton p {
  width: 102.1px;
  height: 20px;
  margin: auto;
  font-family: Lato-SemiBold;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

@media only screen and (max-width: 891px) {
  .biographyDigitalLibraryApprovalImage {
    display: none;
  }

  .biographyDigitalLibraryApprovalTextContainer {
    margin: 28px 10% 15px 10%;
    width: 80%;
  }

  .biographyDigitalLibraryApprovalMessage {
    width: 100%;
    height: fit-content;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 500px) {
}
