.loginEmail {
  grid-area: email;
}

.loginPassword {
  grid-area:password;
}

 @media (min-width: 500px) {
  .loginContainer {
    grid-template-columns: 1fr;
    grid-template-areas: 
      "loginEmail"
      "loginPassword"
  }
}

@media (min-width: 700px) {
  .loginContainer {
    display: grid;
    grid-column-gap:20px;
    grid-template-columns: 1fr;
    grid-template-areas: 
      "loginEmail"
      "loginPassword"
  }
} 
