@media (max-width: 600px) {
    .buttonSelectionStage2Layout{
        padding:46px 10px
    }
}

@media (min-width: 600px) {
    .buttonSelectionStage2Layout{
        padding:46px 100px;
        padding-top: 0px;
    }
}