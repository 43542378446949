.top-three-goals-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.goals-title {
  width: 100%;
  color: black;
  font-family: "Lato-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
}

.goals-container {
  display: flex;
  margin-top: 1em;
}

.goal-frame:first-child {
  margin-left: 0;
}

.goal-frame:last-child {
  margin-right: 0;
}

.goal-frame {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: white;
  flex: 1 1;
  margin: 6px;
  height: 261px;
  max-height: 261px;
  justify-content: flex-end;
  text-align: center;
  border-radius: 5px;
  background-size: cover;
}

.goal-frame .goal-text {
  font-family: Lato-Black;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 53px;
  text-align: left;
}

@media only screen and (max-width: 650px) {
  .goals-container {
    margin-top: 0px;
    flex-direction: column;
  }

  .goal-frame {
    background-color: #030e25;
    background-image: none !important;
    margin: 6px 0px;
  }

  .goal-frame .goal-text {
    font-size: 16px;
    line-height: 19px;
    font-family: Lato-Bold;
    font-style: normal;
    text-align: left;
    padding: 0;
  }

  .dvs-landing-container {
    margin-bottom: 30px;
  }

  .goals-title {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 10px;
  }
}
