.branchOfService {
  grid-area: branchOfService;
}

.specialtyProfile {
  grid-area: specialtyProfile;
}

.rank {
  grid-area: rank;
}

.duty {
  grid-area: duty;
}

@media (max-width: 425px) {

  .profileMilitaryOneContainer {
    grid-template-columns: 1fr;
    grid-template-areas: 
      "branchOfService"
      "specialtyProfile"
      "rank"
      "duty";
  }
}

@media (min-width: 768px) {
  .profileMilitaryOneContainer {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "branchOfService rank"
      "specialtyProfile duty";
  }
}

@media (min-width: 992px) {
  .profileMilitaryOneContainer {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "branchOfService rank"
      "specialtyProfile duty";  }
}
